import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dai Savings Rate`}</h1>
    <p>{`The Dai Savings Rate (DSR) is a variable rate of accrual earned by locking Dai in the DSR smart contract.`}</p>
    <p>{`Its purpose is to allow Maker governance to influence demand for Dai through changes in monetary policy.`}</p>
    <p>{`Dai holders can earn savings automatically and natively while retaining control of their Dai. `}</p>
    <p>{`The DSR smart contract has no withdrawal limits, deposit limits, or liquidity constraints and the rate is actively set by MKR token holders through `}<a parentName="p" {...{
        "href": "/learn/governance/"
      }}>{`on-chain governance`}</a>{`.`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`How it Works`}</h2>
      <p>{`Dai holders can lock their Dai into the DSR smart contract at any time. Once locked, Dai continuously accrues to the user's balance, based on the current DSR.`}</p>
      <p>{`For more technical details, please visit `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/rates-module/pot-detailed-documentation"
        }}>{`Pot - Detailed Documentation`}</a>{` in our `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/"
        }}>{`Documentation Portal`}</a>{`.`}</p>
    </Box>
    <h2>{`FAQs`}</h2>
    <h3>{`Can the DSR be negative or zero?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`It is not possible for the DSR to be set to a negative rate with the current code-base.`}</p>
      <p>{`In theory, MKR governors can commission a rewrite of the code to be able to support negative rates, however, negative rates on the DSR would likely encourage holders to withdraw their Dai.`}</p>
    </Box>
    <h3>{`Does Dai automatically earn the DSR?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`No. Dai only earns the rate if it is deposited into the DSR smart-contract.`}</p>
    </Box>
    <h3>{`Is the DSR its own smart contract?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`Yes, the DSR is a part of the `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/rates-module"
        }}>{`Rates Module`}</a>{`. This module is responsible for collecting Stability Fees and distributing Dai to DSR users.`}</p>
      <p>{`For a better understanding of the smart-contract structure of the Maker Protocol, visit our `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/"
        }}>{`Documentation Portal`}</a>{`.`}</p>
    </Box>
    <h3>{`When users lock Dai into the DSR, is that Dai pooled?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`Yes, all Dai locked into the DSR is technically pooled together. However, the system tracks deposits on an individual basis.`}</p>
    </Box>
    <h3>{`Do DSR accruals compound or are they only earned on the principal?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`In the code, DSR is continuously compounding at a growth rate of x% per second. For example, when the DSR is set to 2%, it will be accumulating at 1.0000000006279371924910298109948‬% per second.`}</p>
      <p>{`Practically, users experience an annual compounding of the displayed rate. For example, if the DSR was 2%, assuming the user put in 100 Dai, at the end of the first year they would have 102.00, and at the end of the second year they would have 104.04.`}</p>
      <p>{`Technical documentation about how Rates work in the Maker Protocol can be found in our `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/rates-module"
        }}>{`Rates Module`}</a>{` documentation.`}</p>
    </Box>
    <h3>{`What is the formula for the expected accruals earned through the DSR?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`Although the DSR compounds at a growth rate of x% per second, in practice the user experiences an annual compounding of the displayed rate.`}</p>
      <p>{`The easiest way to calculate how much one would earn using the DSR is to use an Annual Compounding interest formula. A Compound Interest Calculator can serve this purpose, simply make sure Compound (n) is set to Annually (1/yr).`}</p>
      <p>{`Formula:`}</p>
      <p>{`A = P(1 + r)^t`}</p>
      <p>{`Where:`}</p>
      <p>{`A = ending value of your deposit`}</p>
      <p>{`P = the principal deposit amount`}</p>
      <p>{`r = the annual interest rate (decimal)`}</p>
      <p>{`t = the number of years the principal amount will stay in the DSR`}</p>
    </Box>
    <h3>{`Are there constraints such as minimum lockup time or withdrawal limits?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`Dai in DSR has no liquidity or time constraints. There is no minimum lockup time and no withdrawal limit for Dai in the DSR.`}</p>
      <p>{`Users can freely enter into and out of the DSR, only needing to pay the Ethereum transaction fee.`}</p>
    </Box>
    <h3>{`Does Dai earned by the DSR get calculated and paid out at the time of withdrawal, or is it continuously added to the user’s balance?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`The amount earned is recalculated and counted towards each user’s balance multiple times a day. In practice, the DSR compounds once a year and is paid out pro-rata at the time of withdrawal.`}</p>
      <p>{`The displayed rate is what a user would earn on their principal in one-year using a simple interest calculation. For example, if the DSR is 2% and a user deposits 100 Dai they can expect to have 102 Dai in exactly 1 year.`}</p>
      <p>{`Technical documentation about how rates work in the Maker Protocol can be found in our Rates Module documentation.`}</p>
    </Box>
    <h3>{`How is the DSR being used by MKR token holders as a monetary policy tool?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`The DSR is a global parameter that can be raised or lowered to influence demand for Dai.`}</p>
      <p>{`Raising the DSR incentivizes users to hold more Dai, leading to higher demand for Dai, whereas lowering the DSR has the opposite effect of reducing demand for Dai.`}</p>
      <p>{`This is reflected in the spot market price of Dai; if Dai is trading below a dollar, then the DSR can be raised to increase demand for Dai which would bring up the price of Dai.`}</p>
      <p>{`Conversely, if Dai is trading above a dollar, then the DSR can be lowered to reduce the demand for holding Dai which may help bring down the price of Dai.`}</p>
    </Box>
    <h3>{`Where can someone find information to better understand the economic impact of the DSR?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`The In-Depth Analysis section of the Awesome-MakerDAO resource library offers comprehensive literature on this topic. This section is always expanding as more information becomes available.`}</p>
    </Box>
    <h3>{`Does the DSR reduce the MKR burn rate?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`To answer this question accurately, one must understand what the value of the DSR is. The DSR is a cost to the system, but it is also one of the most important drivers of demand for Dai. The value that it brings as a monetary policy tool and source of Dai demand largely outweighs its cost.`}</p>
    </Box>
    <h3>{`Are stability fees structurally higher due to the existence of the DSR?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`Yes.`}</p>
    </Box>
    <h3>{`Where can I find more technical information about the DSR?`}</h3>
    <Box sx={{
      px: 4
    }} mdxType="Box">
      <p>{`Visit our `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/"
        }}>{`Documentation Portal`}</a>{` for all technical documentation of the Maker Protocol.`}</p>
      <p>{`Technical documentation of the DSR is included in the `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/rates-module/pot-detailed-documentation"
        }}>{`Pot - Detailed Documentation`}</a>{` subsection.`}</p>
      <p>{`And documentation about how rates work in the Maker Protocol is located in the `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/rates-module"
        }}>{`Rates Module`}</a>{` section.`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      